import { getFrameworkVars, getAuthState } from "@sparky/framework";
import { isNil } from "lodash";

export const AIOPS_FREE_PATH = "/aiops-free";

function getFrameworkInstance () {
  const authData = getAuthState();

  if (getFrameworkVars().aiops_free && authData.instances?.isRunningStatus("strata_insights_free")) {
    // aiops free fqdn
    return "free";
  } else if (!getFrameworkVars().aiops_free && authData.instances?.isRunningStatus("strata_insights_free") && !authData.instances?.isRunningStatus("strata_insights")) {
    // scenario where a tenant with aiops free + other instances loads in SCM instead of aiops free domain
    // in this scenario the tenant has aiops free instance but getFrameworkVars().aiops_free is false
    // HybridFramework
    return "free";
  } else {
    // fallback
    return getFrameworkVars().aiops_free ? "free" : "premium";
  }
}

function getInstanceTier () {
  return {
    frameworkInstance: getFrameworkInstance(),
    // scenario where a tenant with aiops free + other instances loads in SCM instead of aiops free domain
    // in this scenario the tenant has aiops free instance but getFrameworkVars().aiops_free is false
    isHybridFramework: getAuthState().instances?.isRunningStatus("strata_insights_free") && !getFrameworkVars().aiops_free
  };
}

/**
 * Check if instance tier is valid
 * @param {*} authData - auth date
 * @param {*} instanceTier - current instance tier
 * @info In One app we detect inctance tier based on path see getInstanceTier.
 *       There is chance that user with only free license can manually update url.
 *       This validator checks if user actually has strata_insights(premium) instance
 *       Also it returns true In case if we run in non AIOps context -
 *          when there are no strata_insights/strata_insights_free
 * @returns boolean
 */
/**
 * SCM now supports new SKU's SCM Base and Premium whose app_id is strata_cloud_manager
 * if this app id contains entitlements then it is an SCM premium tenant
 * if this app id does not contain entitlements then it is an SCM Base tenant
 * Customers will be migrated from old skus to new ones.Once migration is fully comoplete we can remove references to old sku's
 * */
export const isInstanceTierValid = (authData: { instances: any[] }, instanceTier: string) => {
  if (instanceTier !== "premium") {
    return true;
  }
  let hasFreeInstance = false;
  let hasPremiumInstance = false;
  let hasPAInstance;

  authData?.instances?.forEach((instance) => {
    if (instance?.app_id === "strata_insights" || (instance?.app_id === "strata_cloud_manager" && instance?.entitlements?.length)) {
      hasPremiumInstance = true;
    }
    if (instance?.app_id === "strata_insights_free" || (instance?.app_id === "strata_cloud_manager" && isNil(instance?.entitlements))) {
      hasFreeInstance = true;
    }
    if (instance?.app_id.startsWith("prisma_access")) {
      hasPAInstance = true;
    }
  });
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return hasPAInstance || hasPremiumInstance || !hasFreeInstance;
};
export default getInstanceTier;
